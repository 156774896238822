export const terms = `
## 利用規約
この利用規約は、lapi.gqが提供するサービス「FurPort」（以下、当サービス）における利用条件を定めるものです。当サービスの利用者は、本規約に同意するものといたします。

1. 当サービスの利用者は、自己の責任において、当サービスのユーザーIDおよびパスワードを適切に管理するものとします。

1. lapi.gqは、投稿されたすべての情報を予告なく閲覧し改変または削除できるものとします。

1. lapi.gqは、ユーザーに通知することなく当サービスの内容を変更しまたは当サービスの提供を中止することができるものとし、これによってユーザーに生じた損害について一切の責任を負いません。

1. lapi.gqは、ユーザーに通知することなくいつでも本規約を変更することができるものとします。

1. 当サービスの利用者は、下記のいずれかに該当または類似する行為をしてはなりません。

    1. 法令または公序良俗に違反する行為
    1. 当サービス、当サービスの他の利用者、または第三者のサーバーまたはネットワークの機能を破壊したり、妨害したりする行為
    1. 当サービスの運営を妨害するおそれのある行為
    1. 不正アクセスをし、またはこれを試みる行為
    1. 当サービスの他の利用者または第三者の知的財産権、肖像権、プライバシー、名誉その他の権利または利益を侵害する行為
    1. 以下の表現を含む内容を当サービス上に投稿し、または送信する行為
        1. 過度に暴力的な表現
        1. 露骨な性的表現
        1. 人種、国籍、信条、性別、社会的身分、門地等による差別につながる表現
        1. その他反社会的な内容を含み他人に不快感を与える表現
    1. 以下を目的とする行為
        1. 営業、宣伝、広告、勧誘、その他営利目的
        1. 当サービスの他の利用者に対する嫌がらせや誹謗中傷を目的とする行為
        1. 当サービスの他の利用者、または第三者に不利益、損害または不快感を与えることを目的とする行為
        1. 宗教活動または宗教団体への勧誘
    1. その他、lapi.gqが不適切と判断する行為
`;
