export const privacyPolicy = `
## プライバシーポリシー
lapi.gqは、本ウェブサイト上で提供するサービス「FurPort」（以下、当サービス）におけるユーザーの個人情報の取扱いについて、以下のとおりプライバシーポリシー（以下、「当ポリシー」といいます。）を定めます。

1. 当サービスは、以下の個人情報を収集・保管します。

    1. メールアドレス
1. サービスは、ユーザーが外部サービスとの連携を許可した場合、以下の情報を当該外部サービスから取得・保管します。

    1. 外部サービスでユーザーが利用するID
    1. 外部サービスへのアクセストークン
    1. その他外部サービスの設定によりユーザーが開示を認めた情報
1. 当サービスは、アクセス解析ツール「Googleアナリティクス」によって技術情報を取得・保管します。Googleアナリティクスにおける個人情報の取り扱いについては以下のサイトを参照してください。

    1. [Googleアナリティクス利用規約](https://marketingplatform.google.com/about/analytics/terms/jp/)
    1. [Googleプライバシーポリシー](https://policies.google.com/privacy)
1. 当サービスは、収集した個人情報をサービスの提供・運営のため必要な範囲で利用いたします。

1. 当サービスは、あらかじめユーザーの同意を得ることなく第三者に個人情報を提供することはありません。ただし、個人情報保護法その他の法令で認められる場合を除きます。

1. 当ポリシーの内容は、ユーザーに通知することなくいつでも変更することができるものとします。
`;
